import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { IZZZBangboo } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { Range, getTrackBackground } from 'react-range';

import './employee.scss';
import './zzz-character-dynamic.scss';
import { Accordion, Col, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faChartLine,
  faCircleInfo,
  faSquare
} from '@fortawesome/free-solid-svg-icons';

interface IZZZBangbooNodes {
  nodes: IZZZBangboo[];
}

interface IZZZBangbooEntry {
  currentUnit: IZZZBangbooNodes;
}

interface IProps {
  data: IZZZBangbooEntry;
}

const ZZZBangbooDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];
  const [currentTab, setCurrentTab] = useState('Info');
  const [color, setColor] = useState('#979797');
  const [currentLevel, setCurrentLevel] = useState([1]);

  useEffect(() => {
    if (character.rarity === 'B') {
      setColor('#038AEA');
    } else if (character.rarity === 'A') {
      setColor('#B263E1');
    } else if (character.rarity === 'S') {
      setColor('#D7BC57');
    }
  }, []);

  const profileImage = character.cardImage
    ? getImage(character.cardImage.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-zzz'}
      game="zzz"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/bangboo">Bangboo</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <>
        <div className="character-top">
          <div className="left-info">
            <h1>
              <span className="small"></span>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>
              <span className="sub">Bangboo Information</span>
            </h1>
          </div>
          <div className="right-image">
            <div
              className={`character-background bangboo rarity-${character.rarity} ${character.slug}`}
            ></div>
            <GatsbyImage image={profileImage} alt="Character" />
          </div>
        </div>
        <Row className="intro-section">
          <Col xs={12} xl={12}>
            <div className={`content-header rarity-${character.rarity}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Introduction
            </div>
            <div className="character-intro">
              <div className="combined">
                <h2>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  is a
                  {character.rarity === 'A' && (
                    <StaticImage
                      src="../images/zzz/icons/rarity_a.png"
                      alt="A Rank"
                      className="faction-icon"
                    />
                  )}
                  {character.rarity === 'S' && (
                    <StaticImage
                      src="../images/zzz/icons/rarity_s.png"
                      alt="S Rank"
                      className="faction-icon"
                    />
                  )}{' '}
                  rank Bangboo. Bangboo are 'pets' in Zenless Zone Zero that
                  help your team with both active and passive skills. To learn
                  more about{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  check the sections below. <strong>Use the tabs</strong> to
                  quickly switch to the kind of information you're looking for.
                </h2>
              </div>
            </div>
          </Col>
        </Row>
        <div
          className="fuse-ad-placeholder bigger"
          data-fuse="22844297232"
        ></div>
        <div className="tabs bangboo">
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Info' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Info')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
            </div>
            <p>Profile</p>
          </div>
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Review' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Review')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faBook} width="18" />
            </div>
            <p>Review</p>
          </div>
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Performance' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Performance')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faChartLine} width="18" />
            </div>
            <p>Calculations</p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Info' ? 'active' : ''}`}>
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Profile
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Skills
          </div>
          <div className="info-box with-margin ">
            <p>
              Skill A and C can be leveled up to <strong>level 10</strong> (5
              from dupes and 5 from levels) while Skill B can only be leveled
              using the dupes, so it's capped at <strong>level 5</strong>.
            </p>
          </div>
          <div className="stats-zzz skills">
            <div className="stats-header">
              <div className="info">
                <p>Use the slider to change the level of skills.</p>
              </div>
              <div className="level-changer">
                <span className="current-level">
                  Level.{' '}
                  <span className="level" style={{ color: color }}>
                    {currentLevel}
                  </span>
                </span>
                <div className={`level-slider `}>
                  <Range
                    step={1}
                    min={1}
                    max={10}
                    values={currentLevel}
                    onChange={(values) => setCurrentLevel(values)}
                    renderTrack={({ props, children }) => (
                      <div
                        role="button"
                        tabIndex={0}
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                          ...props.style,
                          height: '36px',
                          display: 'flex',
                          width: '100%'
                        }}
                      >
                        <div
                          ref={props.ref}
                          style={{
                            height: '5px',
                            width: '100%',
                            borderRadius: '0px',
                            background: getTrackBackground({
                              values: currentLevel,
                              colors: [color, '#484950'],
                              min: 1,
                              max: 10
                            }),
                            alignSelf: 'center'
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: '20px',
                          width: '20px',
                          borderRadius: '0px',
                          backgroundColor: '#FFF',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <div
                          style={{
                            height: '14px',
                            width: '5px',
                            backgroundColor: isDragged ? color : '#484950'
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="skills">
            <Row xs={1} xl={3} xxl={3}>
              <Col>
                <div className="box">
                  <div className={`category-header rarity-${character.rarity}`}>
                    Skill A - Active
                  </div>
                  <div className="skill-header">
                    <div className="skill-info">
                      <p className="skill-name">
                        {character.skills.skill_a.name
                          ? character.skills.skill_a.name
                          : 'Unknown name'}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`skill-description bangboo ${character.skills.skill_a.element}`}
                    dangerouslySetInnerHTML={{
                      __html: character.skills.skill_a.description
                    }}
                  />
                  <Accordion className="accordion-multis">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Multipliers</Accordion.Header>
                      <Accordion.Body>
                        <div className="multipliers inside">
                          {character.slug === 'exploreboo' ||
                          character.slug === 'butler' ||
                          character.slug === 'knightboo' ||
                          character.slug === 'booressure' ||
                          character.slug === 'bangvolver' ||
                          character.slug === 'avocaboo' ? (
                            <>
                              {character.slug === 'knightboo' && (
                                <>
                                  <div className="multi-single">
                                    <div className="name">
                                      Energy Generation
                                    </div>
                                    <div className="value">
                                      {Number(currentLevel) === 1 && <>0.5</>}
                                      {Number(currentLevel) === 2 && <>0.6</>}
                                      {Number(currentLevel) === 3 && <>0.7</>}
                                      {Number(currentLevel) === 4 && <>0.8</>}
                                      {Number(currentLevel) === 5 && <>0.9</>}
                                      {Number(currentLevel) === 6 && <>1</>}
                                      {Number(currentLevel) === 7 && <>1.1</>}
                                      {Number(currentLevel) === 8 && <>1.2</>}
                                      {Number(currentLevel) === 9 && <>1.3</>}
                                      {Number(currentLevel) === 10 && (
                                        <>1.4</>
                                      )}{' '}
                                      + Bangboo Level × 0.01
                                    </div>
                                  </div>
                                  <div className="multi-single">
                                    <div className="name">Cooldown</div>
                                    <div className="value">24s</div>
                                  </div>
                                </>
                              )}
                              {character.slug === 'exploreboo' && (
                                <>
                                  <div className="multi-single">
                                    <div className="name">HP Recovery</div>
                                    <div className="value">
                                      {Number(currentLevel) === 1 && <>8% HP</>}
                                      {Number(currentLevel) === 2 && (
                                        <>8.2% HP</>
                                      )}
                                      {Number(currentLevel) === 3 && (
                                        <>8.4% HP</>
                                      )}
                                      {Number(currentLevel) === 4 && (
                                        <>8.6% HP</>
                                      )}
                                      {Number(currentLevel) === 5 && (
                                        <>8.8% HP</>
                                      )}
                                      {Number(currentLevel) === 6 && (
                                        <>9.0% HP</>
                                      )}
                                      {Number(currentLevel) === 7 && (
                                        <>9.2% HP</>
                                      )}
                                      {Number(currentLevel) === 8 && (
                                        <>9.4% HP</>
                                      )}
                                      {Number(currentLevel) === 9 && (
                                        <>9.6% HP</>
                                      )}
                                      {Number(currentLevel) === 10 && (
                                        <>9.8% HP</>
                                      )}
                                    </div>
                                  </div>
                                  <div className="multi-single">
                                    <div className="name">HP Shield</div>
                                    <div className="value">
                                      {Number(currentLevel) === 1 && <>9% HP</>}
                                      {Number(currentLevel) === 2 && (
                                        <>9.3% HP</>
                                      )}
                                      {Number(currentLevel) === 3 && (
                                        <>9.6% HP</>
                                      )}
                                      {Number(currentLevel) === 4 && (
                                        <>9.9% HP</>
                                      )}
                                      {Number(currentLevel) === 5 && (
                                        <>10.2% HP</>
                                      )}
                                      {Number(currentLevel) === 6 && (
                                        <>10.5% HP</>
                                      )}
                                      {Number(currentLevel) === 7 && (
                                        <>10.8% HP</>
                                      )}
                                      {Number(currentLevel) === 8 && (
                                        <>11.1% HP</>
                                      )}
                                      {Number(currentLevel) === 9 && (
                                        <>11.4% HP</>
                                      )}
                                      {Number(currentLevel) === 10 && (
                                        <>11.7% HP</>
                                      )}
                                    </div>
                                  </div>
                                  <div className="multi-single">
                                    <div className="name">Energy Gen</div>
                                    <div className="value">
                                      {Number(currentLevel) === 1 && <>0.2s</>}
                                      {Number(currentLevel) === 2 && <>0.22s</>}
                                      {Number(currentLevel) === 3 && <>0.24s</>}
                                      {Number(currentLevel) === 4 && <>0.26s</>}
                                      {Number(currentLevel) === 5 && <>0.28s</>}
                                      {Number(currentLevel) === 6 && <>0.3s</>}
                                      {Number(currentLevel) === 7 && <>0.32s</>}
                                      {Number(currentLevel) === 8 && <>0.34s</>}
                                      {Number(currentLevel) === 9 && <>0.36s</>}
                                      {Number(currentLevel) === 10 && (
                                        <>0.38s</>
                                      )}
                                    </div>
                                  </div>
                                  <div className="multi-single">
                                    <div className="name">Buff Duration</div>
                                    <div className="value">12s</div>
                                  </div>
                                  <div className="multi-single">
                                    <div className="name">Cooldown</div>
                                    <div className="value">16s</div>
                                  </div>
                                </>
                              )}
                              {character.slug === 'butler' && (
                                <>
                                  <div className="multi-single">
                                    <div className="name">
                                      Energy Generation
                                    </div>
                                    <div className="value">
                                      {Number(currentLevel) === 1 && (
                                        <>3 + Bangboo level x0.08</>
                                      )}
                                      {Number(currentLevel) === 2 && (
                                        <>3.2 + Bangboo level x0.08</>
                                      )}
                                      {Number(currentLevel) === 3 && (
                                        <>3.4 + Bangboo level x0.08</>
                                      )}
                                      {Number(currentLevel) === 4 && (
                                        <>3.6 + Bangboo level x0.08</>
                                      )}
                                      {Number(currentLevel) === 5 && (
                                        <>3.8 + Bangboo level x0.08</>
                                      )}
                                      {Number(currentLevel) === 6 && (
                                        <>4 + Bangboo level x0.08</>
                                      )}
                                      {Number(currentLevel) === 7 && (
                                        <>4.2 + Bangboo level x0.08</>
                                      )}
                                      {Number(currentLevel) === 8 && (
                                        <>4.4 + Bangboo level x0.08</>
                                      )}
                                      {Number(currentLevel) === 9 && (
                                        <>4.6 + Bangboo level x0.08</>
                                      )}
                                      {Number(currentLevel) === 10 && (
                                        <>4.8 + Bangboo level x0.08</>
                                      )}
                                    </div>
                                  </div>
                                  <div className="multi-single">
                                    <div className="name">Cooldown</div>
                                    <div className="value">24s</div>
                                  </div>
                                </>
                              )}
                              {character.slug === 'avocaboo' && (
                                <>
                                  <div className="multi-single">
                                    <div className="name">HP Recovery</div>
                                    <div className="value">
                                      {Number(currentLevel) === 1 && (
                                        <>10% HP</>
                                      )}
                                      {Number(currentLevel) === 2 && (
                                        <>10.5% HP</>
                                      )}
                                      {Number(currentLevel) === 3 && (
                                        <>11% HP</>
                                      )}
                                      {Number(currentLevel) === 4 && (
                                        <>11.5% HP</>
                                      )}
                                      {Number(currentLevel) === 5 && (
                                        <>12% HP</>
                                      )}
                                      {Number(currentLevel) === 6 && (
                                        <>12.5% HP</>
                                      )}
                                      {Number(currentLevel) === 7 && (
                                        <>13% HP</>
                                      )}
                                      {Number(currentLevel) === 8 && (
                                        <>13.5% HP</>
                                      )}
                                      {Number(currentLevel) === 9 && (
                                        <>14% HP</>
                                      )}
                                      {Number(currentLevel) === 10 && (
                                        <>14.5% HP</>
                                      )}
                                    </div>
                                  </div>
                                  <div className="multi-single">
                                    <div className="name">Cooldown</div>
                                    <div className="value">20s</div>
                                  </div>
                                </>
                              )}
                              {character.slug === 'booressure' && (
                                <>
                                  <div className="multi-single">
                                    <div className="name">
                                      Energy Generation
                                    </div>
                                    <div className="value">
                                      {Number(currentLevel) === 1 && (
                                        <>2.5 + Bangboo level x0.05</>
                                      )}
                                      {Number(currentLevel) === 2 && (
                                        <>2.6 + Bangboo level x0.05</>
                                      )}
                                      {Number(currentLevel) === 3 && (
                                        <>2.7 + Bangboo level x0.05</>
                                      )}
                                      {Number(currentLevel) === 4 && (
                                        <>2.8 + Bangboo level x0.05</>
                                      )}
                                      {Number(currentLevel) === 5 && (
                                        <>2.9 + Bangboo level x0.05</>
                                      )}
                                      {Number(currentLevel) === 6 && (
                                        <>3 + Bangboo level x0.05</>
                                      )}
                                      {Number(currentLevel) === 7 && (
                                        <>3.1 + Bangboo level x0.05</>
                                      )}
                                      {Number(currentLevel) === 8 && (
                                        <>3.2 + Bangboo level x0.05</>
                                      )}
                                      {Number(currentLevel) === 9 && (
                                        <>3.3 + Bangboo level x0.05</>
                                      )}
                                      {Number(currentLevel) === 10 && (
                                        <>3.4 + Bangboo level x0.05</>
                                      )}
                                    </div>
                                  </div>
                                  <div className="multi-single">
                                    <div className="name">Cooldown</div>
                                    <div className="value">26s</div>
                                  </div>
                                </>
                              )}
                              {character.slug === 'bangvolver' && (
                                <>
                                  <div className="multi-single">
                                    <div className="name">
                                      Enhanced Shot DMG Multiplier
                                    </div>
                                    <div className="value">
                                      {Number(currentLevel) === 1 && <>1152%</>}
                                      {Number(currentLevel) === 2 && (
                                        <>1267.2%</>
                                      )}
                                      {Number(currentLevel) === 3 && (
                                        <>1382.4%</>
                                      )}
                                      {Number(currentLevel) === 4 && (
                                        <>1497.6%</>
                                      )}
                                      {Number(currentLevel) === 5 && (
                                        <>1612.8%</>
                                      )}
                                      {Number(currentLevel) === 6 && <>1728%</>}
                                      {Number(currentLevel) === 7 && (
                                        <>1843.2%</>
                                      )}
                                      {Number(currentLevel) === 8 && (
                                        <>1958.4%</>
                                      )}
                                      {Number(currentLevel) === 9 && (
                                        <>2073.6%</>
                                      )}
                                      {Number(currentLevel) === 10 && (
                                        <>2188.8%</>
                                      )}
                                    </div>
                                  </div>
                                  <div className="multi-single">
                                    <div className="name">
                                      Enhanced Shot Daze Multiplier
                                    </div>
                                    <div className="value">
                                      {Number(currentLevel) === 1 && <>421%</>}
                                      {Number(currentLevel) === 2 && (
                                        <>463.1%</>
                                      )}
                                      {Number(currentLevel) === 3 && (
                                        <>505.2%</>
                                      )}
                                      {Number(currentLevel) === 4 && (
                                        <>547.3%</>
                                      )}
                                      {Number(currentLevel) === 5 && (
                                        <>589.4%</>
                                      )}
                                      {Number(currentLevel) === 6 && (
                                        <>631.5%</>
                                      )}
                                      {Number(currentLevel) === 7 && (
                                        <>673.6%</>
                                      )}
                                      {Number(currentLevel) === 8 && (
                                        <>715.7%</>
                                      )}
                                      {Number(currentLevel) === 9 && (
                                        <>757.8%</>
                                      )}
                                      {Number(currentLevel) === 10 && (
                                        <>799.9%</>
                                      )}
                                    </div>
                                  </div>
                                  <div className="multi-single">
                                    <div className="name">
                                      Shot DMG Multiplier
                                    </div>
                                    <div className="value">
                                      {Number(currentLevel) === 1 && <>576%</>}
                                      {Number(currentLevel) === 2 && (
                                        <>633.6%</>
                                      )}
                                      {Number(currentLevel) === 3 && (
                                        <>691.2%</>
                                      )}
                                      {Number(currentLevel) === 4 && (
                                        <>748.8%</>
                                      )}
                                      {Number(currentLevel) === 5 && (
                                        <>806.4%</>
                                      )}
                                      {Number(currentLevel) === 6 && <>864%</>}
                                      {Number(currentLevel) === 7 && (
                                        <>921.6%</>
                                      )}
                                      {Number(currentLevel) === 8 && (
                                        <>979.2%</>
                                      )}
                                      {Number(currentLevel) === 9 && (
                                        <>1036.8%</>
                                      )}
                                      {Number(currentLevel) === 10 && (
                                        <>1094.4%</>
                                      )}
                                    </div>
                                  </div>
                                  <div className="multi-single">
                                    <div className="name">
                                      Shot Daze Multiplier
                                    </div>
                                    <div className="value">
                                      {Number(currentLevel) === 1 && <>211%</>}
                                      {Number(currentLevel) === 2 && (
                                        <>232.1%</>
                                      )}
                                      {Number(currentLevel) === 3 && (
                                        <>252.2%</>
                                      )}
                                      {Number(currentLevel) === 4 && (
                                        <>273.3%</>
                                      )}
                                      {Number(currentLevel) === 5 && (
                                        <>294.4%</>
                                      )}
                                      {Number(currentLevel) === 6 && (
                                        <>315.5%</>
                                      )}
                                      {Number(currentLevel) === 7 && (
                                        <>336.6%</>
                                      )}
                                      {Number(currentLevel) === 8 && (
                                        <>357.7%</>
                                      )}
                                      {Number(currentLevel) === 9 && (
                                        <>379.8%</>
                                      )}
                                      {Number(currentLevel) === 10 && (
                                        <>400.8%</>
                                      )}
                                    </div>
                                  </div>
                                  <div className="multi-single">
                                    <div className="name">Cooldown</div>
                                    <div className="value">18s</div>
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <div className="multi-single">
                                <div className="name">DMG Multiplier</div>
                                <div className="value">
                                  {(
                                    character.skills.skill_a.base_multi +
                                    Number(currentLevel) *
                                      character.skills.skill_a.rank_multi -
                                    character.skills.skill_a.rank_multi
                                  ).toFixed(1)}
                                  %{character.slug === 'luckyboo' && <>*140</>}
                                  {character.slug === 'officer-cui' && <>*2</>}
                                </div>
                              </div>
                              <div className="multi-single">
                                <div className="name">Daze Multiplier</div>
                                <div className="value">
                                  {(
                                    character.skills.skill_a.daze +
                                    Number(currentLevel) *
                                      character.skills.skill_a.daze_rank -
                                    character.skills.skill_a.daze_rank
                                  ).toFixed(1)}
                                  %{character.slug === 'luckyboo' && <>*140</>}
                                  {character.slug === 'officer-cui' && <>*2</>}
                                </div>
                              </div>
                              <div className="multi-single">
                                <div className="name">Cooldown</div>
                                <div className="value">
                                  {character.skills.skill_a.cooldown}s
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
              <Col>
                <div className="box">
                  <div className={`category-header rarity-${character.rarity}`}>
                    Skill B - Passive
                  </div>
                  <div className="skill-header">
                    <div className="skill-info">
                      <p className="skill-name">
                        {character.skills.skill_b.name
                          ? character.skills.skill_b.name
                          : 'Unknown name'}
                      </p>
                    </div>
                  </div>
                  {character.skills.skill_b.description.map(
                    (upgrade, index) => {
                      return (
                        <div key={index}>
                          {Number(currentLevel) === 1 && index === 0 && (
                            <div
                              className={`skill-description bangboo ${character.skills.skill_b.element}`}
                              dangerouslySetInnerHTML={{
                                __html: upgrade.desc
                              }}
                            />
                          )}
                          {Number(currentLevel) === 2 && index === 1 && (
                            <div
                              className={`skill-description bangboo ${character.skills.skill_b.element}`}
                              dangerouslySetInnerHTML={{
                                __html: upgrade.desc
                              }}
                            />
                          )}
                          {Number(currentLevel) === 3 && index === 2 && (
                            <div
                              className={`skill-description bangboo ${character.skills.skill_b.element}`}
                              dangerouslySetInnerHTML={{
                                __html: upgrade.desc
                              }}
                            />
                          )}
                          {Number(currentLevel) === 4 && index === 3 && (
                            <div
                              className={`skill-description bangboo ${character.skills.skill_b.element}`}
                              dangerouslySetInnerHTML={{
                                __html: upgrade.desc
                              }}
                            />
                          )}
                          {Number(currentLevel) >= 5 && index >= 4 && (
                            <div
                              className={`skill-description bangboo ${character.skills.skill_b.element}`}
                              dangerouslySetInnerHTML={{
                                __html: upgrade.desc
                              }}
                            />
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              </Col>
              {character.skills.skill_c.name != '' && (
                <Col>
                  <div className="box">
                    <div
                      className={`category-header rarity-${character.rarity}`}
                    >
                      Skill C - Chain Attack
                    </div>
                    <div className="skill-header">
                      <div className="skill-info">
                        <p className="skill-name">
                          {character.skills.skill_c.name
                            ? character.skills.skill_c.name
                            : 'Unknown name'}
                        </p>
                      </div>
                    </div>
                    <div
                      className={`skill-description bangboo ${character.skills.skill_c.element}`}
                      dangerouslySetInnerHTML={{
                        __html: character.skills.skill_c.description
                      }}
                    />
                    <Accordion className="accordion-multis">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Multipliers</Accordion.Header>
                        <Accordion.Body>
                          <div className="multipliers inside">
                            <div className="multi-single">
                              <div className="name">DMG Multiplier</div>
                              <div className="value">
                                {(
                                  character.skills.skill_c.base_multi +
                                  Number(currentLevel) *
                                    character.skills.skill_c.rank_multi -
                                  character.skills.skill_c.rank_multi
                                ).toFixed(1)}
                                %
                              </div>
                            </div>
                            <div className="multi-single">
                              <div className="name">Daze Multiplier</div>
                              <div className="value">
                                {(
                                  character.skills.skill_c.daze +
                                  Number(currentLevel) *
                                    character.skills.skill_c.daze_rank -
                                  character.skills.skill_c.daze_rank
                                ).toFixed(1)}
                                %
                              </div>
                            </div>
                            {character.slug === 'devilboo' && (
                              <>
                                <div className="multi-single">
                                  <div className="name">
                                    Coordinated Attack DMG Multiplier
                                  </div>
                                  <div className="value">
                                    {Number(currentLevel) === 1 && <>37%*2</>}
                                    {Number(currentLevel) === 2 && <>40.7%*2</>}
                                    {Number(currentLevel) === 3 && <>44.4%*2</>}
                                    {Number(currentLevel) === 4 && <>48.1%*2</>}
                                    {Number(currentLevel) === 5 && <>51.8%*2</>}
                                    {Number(currentLevel) === 6 && <>55.5%*2</>}
                                    {Number(currentLevel) === 7 && <>59.2%*2</>}
                                    {Number(currentLevel) === 8 && <>62.9%*2</>}
                                    {Number(currentLevel) === 9 && <>66.6%*2</>}
                                    {Number(currentLevel) === 10 && (
                                      <>70.3%*2</>
                                    )}
                                  </div>
                                </div>
                                <div className="multi-single">
                                  <div className="name">
                                    Coordinated Attack Daze Multiplier
                                  </div>
                                  <div className="value">
                                    {Number(currentLevel) === 1 && <>5.8%*2</>}
                                    {Number(currentLevel) === 2 && <>6.2%*2</>}
                                    {Number(currentLevel) === 3 && <>6.7%*2</>}
                                    {Number(currentLevel) === 4 && <>7.2%*2</>}
                                    {Number(currentLevel) === 5 && <>7.7%*2</>}
                                    {Number(currentLevel) === 6 && <>8.2%*2</>}
                                    {Number(currentLevel) === 7 && <>8.7%*2</>}
                                    {Number(currentLevel) === 8 && <>9.2%*2</>}
                                    {Number(currentLevel) === 9 && <>9.7%*2</>}
                                    {Number(currentLevel) === 10 && (
                                      <>10.2%*2</>
                                    )}
                                  </div>
                                </div>
                                <div className="multi-single">
                                  <div className="name">
                                    Coordination State Duration
                                  </div>
                                  <div className="value">30s</div>
                                </div>
                                <div className="multi-single">
                                  <div className="name">
                                    Additional Coordinated Attacks
                                  </div>
                                  <div className="value">10 Activations</div>
                                </div>
                              </>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Col>
              )}
            </Row>
          </div>
          {/* <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>skills aren't available yet.</strong> They will be added
              soon!
            </p>
          </div> */}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Stats (Level 60)
          </div>
          <div className="stats-zzz">
            <Row xs={1} xl={1} xxl={2} className="info-list stats">
              <Col>
                <div className="stat-box">
                  <div className="info-list-row">
                    <div className="category">
                      <StaticImage
                        src="../images/zzz/icons/stat_hp.png"
                        alt="Stat"
                      />
                      HP
                    </div>
                    <div className={`details`}>{character.statsNew.hp}</div>
                  </div>
                  <div className="info-list-row">
                    <div className="category">
                      <StaticImage
                        src="../images/zzz/icons/stat_atk.png"
                        alt="Stat"
                      />
                      ATK
                    </div>
                    <div className={`details`}>{character.statsNew.atk}</div>
                  </div>
                  <div className="info-list-row">
                    <div className="category">
                      <StaticImage
                        src="../images/zzz/icons/stat_def.png"
                        alt="Stat"
                      />
                      DEF
                    </div>
                    <div className={`details`}>{character.statsNew.def}</div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="stat-box">
                  <div className="info-list-row">
                    <div className="category">
                      <StaticImage
                        src="../images/zzz/icons/stat_crit.png"
                        alt="Stat"
                      />
                      CRIT Rate
                    </div>
                    <div className={`details`}>50%</div>
                  </div>
                  <div className="info-list-row">
                    <div className="category">
                      <StaticImage
                        src="../images/zzz/icons/stat_critdmg.png"
                        alt="Stat"
                      />
                      CRIT DMG
                    </div>
                    <div className={`details`}>100%</div>
                  </div>
                  <div className="info-list-row">
                    <div className="category with-help">
                      <StaticImage
                        src="../images/zzz/icons/stat_impact.png"
                        alt="Stat"
                      />
                      Impact{' '}
                    </div>
                    <div className={`details`}>{character.statsNew.impact}</div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div
          className={`tab-inside ${currentTab === 'Review' ? 'active' : ''}`}
        >
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Review
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Review
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>review isn't available yet.</strong> It will be added
              soon!
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>pros & cons aren't available yet.</strong> It will be
              added soon!
            </p>
          </div>
        </div>
        <div
          className={`tab-inside ${
            currentTab === 'Performance' ? 'active' : ''
          }`}
        >
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Calculations
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Calculations
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>calculations aren't available yet.</strong>
            </p>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </>
    </DashboardLayout>
  );
};

export default ZZZBangbooDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' | Zenless Zone Zero | Prydwen'}
      description={
        character.name +
        ' is one of the Bangboo available in Zenless Zone Zero (ZZZ) - find all the information you need about them inside!'
      }
      image={character.cardImage}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulZzzBangboo(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...ZzzBangbooFieldsFragment
      }
    }
  }
`;
